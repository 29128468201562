body {
  font-family: 'Arial', sans-serif;
  background-color: #144e27; /* Dark green background */
  color: #fff; /* White text for better contrast */
  margin: 0;
  padding: 0;
}

.otaku-app-container,
.otaku-chapter-container,
.otaku-mangapage-container {
  font-family: 'Arial', sans-serif;
  background-color: #1f6c3c; /* Slightly lighter green background */
  padding: 20px;
  text-align: center;
  border-radius: 10px; /* Added rounded corners */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Added subtle box shadow */
}

/* Title Style */
.otaku-title {
  color: #2ecc71;
  font-size: 28px; /* Increased font size for emphasis */
  font-weight: bold;
}

/* Search Container */
.otaku-search-container {
  text-align: center;
  margin: 20px;
}

/* Input and Select Styles */
.otaku-input,
.otaku-select {
  padding: 14px; /* Increased padding for a softer feel */
  font-size: 18px; /* Increased font size for better visibility */
  border: 2px solid #2ecc71;
  background-color: #2ecc71;
  color: #fff;
  border-radius: 8px; /* Added rounded corners */
  margin-right: 10px;
  margin-bottom: 20px;
}

/* Buttons Styles */
.otaku-button,
.otaku-fetch-button {
  padding: 14px 28px; /* Increased padding for a softer feel */
  font-size: 18px;
  background-color: #2ecc71;
  border: 2px solid #2ecc71;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease; /* Added smooth transition */
}

.otaku-button:hover,
.otaku-fetch-button:hover {
  background-color: #1a9850; /* Darker green on hover */
}

/* Results and Manga Page Containers */
.otaku-results,
.otaku-manga-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Manga Item and Page Button Styles */
.otaku-manga-item,
.otaku-manga-page-button {
  margin: 10px;
  text-align: center;
  font-size: 16px; /* Adjusted font size for better readability */
}

/* Manga Image and Page Image Styles */
.otaku-manga-image,
.otaku-manga-page-img {
  width: 100%;
  max-width: 600px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Slightly increased box shadow */
}

/* Selected Chapter Style */
.otaku-selected-chapter {
  color: #2ecc71;
  margin-top: 10px;
}

/* Dropdown Styles */
.otaku-dropdown {
  width: 300px;
}

.otaku-dropdown-option {
  font-size: 16px;
  color: #333;
}

.otaku-dropdown-option:hover {
  background-color: #ff4081;
  color: #fff;
}

/* Dropdown No Results and Placeholder Styles */
.otaku-dropdown-no-results,
.otaku-dropdown-placeholder {
  color: #ff4081;
}

/* Search Input Style */
.otaku-search-input {
  width: 300px;
  margin-top: 10px;
}

/* Dark Mode Styles */
.otaku-dark-mode {
  background-color: #0d361b; /* Darker green for dark mode */
  color: #2da35e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Media Query for Mobile */
@media only screen and (max-width: 600px) {
  .otaku-input,
  .otaku-select,
  .otaku-button,
  .otaku-fetch-button {
    width: 100%;
  }
}